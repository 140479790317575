import { template as template_6420f84ff24744d7866283a58be6875a } from "@ember/template-compiler";
const FKText = template_6420f84ff24744d7866283a58be6875a(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
