import { template as template_bdb2e5d5a7e44e7a8e486a8809330614 } from "@ember/template-compiler";
const FKControlMenuContainer = template_bdb2e5d5a7e44e7a8e486a8809330614(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
